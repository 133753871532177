(function ($) {
  Drupal.behaviors.ELB_MyFeed = {
    attach: function () {
      $('.js-utility-nav-signup-submit').click(function (event) {
        event.preventDefault();
        var offer_url = $(this).attr('data-offer-url');

        sign_up_with_email(offer_url);
      });

      function sign_up_with_email(offer_url) {
        var dest = offer_url || '';
        var email = $('.js-utility-nav-signup-email');
        var emailvalue = email.val();

        if (/^\/.+$/.test(dest)) {
          dest = dest.replace(/^(\s|\/)+/, '');
        }
        if (/^(?!http|\/).+$/.test(dest)) {
          dest = Drupal.settings.basePath + dest;
        }
        if (emailvalue !== $('#default').val() && emailvalue !== '') {
          dest = dest + '?email=' + escape(emailvalue);
          $('.errormessage').addClass('hidden');
          window.location.href = dest;
        } else {
          $('.js-utility-nav-signup-error').removeClass('hidden');
        }

        return false;
      }
    }
  };
})(jQuery);
